import styled from "styled-components"

import { COLORS, LAYOUT } from "src/layout/constants"
import { DateType, formatDate } from "src/helpers/date"
import RichText from "src/components/RichText"

const Box = styled.div<{ $type: DateType }>`
  background-color: ${({ $type }) =>
    $type === "upcoming" ? COLORS.sherpa : $type === "recent" ? COLORS.blue : COLORS.lightBlue};
  flex: 0 0 ${LAYOUT.column / 3 - 2 * LAYOUT.margin}px;
  min-height: 300px;
  margin: ${LAYOUT.margin}px;
  color: ${({ $type }) => ($type === "upcoming" ? COLORS.lightBlue : COLORS.sherpa)};
  padding: ${LAYOUT.margin}px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ $type }) => ($type === "past" ? COLORS.white : "transparent")};
  overflow: hidden;
  overflow-wrap: break-word;
  b,
  strong {
    font-weight: normal;
    color: ${({ $type }) => ($type !== "recent" ? COLORS.green : COLORS.white)};
  }
  span {
    color: ${({ $type }) => ($type === "upcoming" ? COLORS.pink : COLORS.sherpa)};
  }
  @media (min-width: 600px) {
    font-size: 0.8em;
  }
`
const Relative = styled.div`
  text-transform: uppercase;
  margin-bottom: 5px;
`
const DateTitle = styled.h4`
  margin-top: 5px;
`
const Text = styled.div`
  flex: 1;
`
const Tags = styled.div`
  margin-top: ${LAYOUT.margin}px;
  text-align: right;
`

interface Props {
  event: AgendaEvent
  type: DateType
  label?: string
}

const EventBox = ({ event: { date, text, tags }, type, label }: Props) => {
  return (
    <Box $type={type}>
      {label && <Relative>{label}</Relative>}
      <DateTitle>{formatDate(date, "d MMMM yyyy")}</DateTitle>
      <Text>
        <RichText node={text} />
      </Text>
      <Tags>
        {tags.map((tag) => (
          <span key={tag}> #{tag}</span>
        ))}
      </Tags>
    </Box>
  )
}

export default EventBox
