import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "src/layout"
import { PageHead, Column, Grid } from "src/layout/styled"
import { COLORS, LAYOUT } from "src/layout/constants"
import EventBox from "src/components/Event"
import { dateStr, DateType, LABELS } from "src/helpers/date"
import AgendaIcon from "src/svg/agenda.svg"

const NUM_RECENT = 9

const CollapsedGrid = styled(Grid)`
  margin: 0 -${LAYOUT.margin}px;
  position: relative;
  @media (max-width: 999px) {
    margin: 0 ${LAYOUT.margin}px;
  }
`
const SideLabel = styled.div`
  text-transform: uppercase;
  color: ${COLORS.pink};
  @media (max-width: 799px) {
    font-size: 1.5em;
    margin: ${LAYOUT.margin * 3}px ${LAYOUT.margin}px ${LAYOUT.margin}px;
    text-align: center;
  }
  @media (min-width: 800px) {
    font-size: 0.9em;
    position: absolute;
    top: ${LAYOUT.margin}px;
    left: -8px;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }
`

interface Props {
  list: AgendaEvent[]
  type: DateType
}

const Events = ({ list, type }: Props) => {
  if (!list.length) {
    return null
  }

  return (
    <CollapsedGrid>
      <SideLabel>{LABELS[type]}</SideLabel>
      {list.map((event) => (
        <EventBox key={event.id} event={event} type={type} />
      ))}
    </CollapsedGrid>
  )
}

interface Data {
  events: { nodes: AgendaEvent[] }
}

const AgendaPage: GatsbyPage<Data> = ({ data }) => {
  const now = dateStr()
  const upcoming = data.events.nodes.filter(({ date }) => date > now)
  const past = data.events.nodes.filter(({ date }) => date <= now)
  const subtitle = "Suivez notre actualité, nos activités passées et à venir."

  return (
    <Layout theme="blue" title="Agenda" description={subtitle}>
      <PageHead>
        <AgendaIcon />
        <h1>agenda</h1>
        <h2>{subtitle}</h2>
      </PageHead>
      <Column>
        <Events list={upcoming} type="upcoming" />
        <Events list={past.slice(0, NUM_RECENT)} type="recent" />
        <Events list={past.slice(NUM_RECENT)} type="past" />
      </Column>
    </Layout>
  )
}

export default AgendaPage

export const query = graphql`
  query {
    events: allContentfulEvent(sort: { fields: date, order: DESC }) {
      nodes {
        id: contentful_id
        date
        tags
        text {
          json
        }
      }
    }
  }
`
